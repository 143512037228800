<template>
  <div class="manage-news" :class="{skeleton: !state.loaded}">
    <div class="act">
      <button class="btn btn-bordered-point" @click="$store.commit('openModal', {name: 'NewsWrite', callback: 'refresh'})">글쓰기</button>
    </div>
    <template v-if="state.document.list.length">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead class="thead-light">
          <tr>
            <th>
              <span>제목</span>
            </th>
            <th>
              <span>날짜</span>
            </th>
          </tr>
          </thead>
          <tbody class="list">
          <tr class="pointer" v-for="(d, idx) in state.document.list" :key="idx" @click="openModal(d.newsSeq)">
            <td class="budget">
              <span>{{ d.title }}</span>
              <span class="ml-2 text-red" v-if="d.commentsList.length">{{ d.commentsList.length }}</span>
            </td>
            <td class="budget">
              <span>{{ d.createDate }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="page">
        <Pagination :info="state.document.paginationInfo" :change="load" v-if="state.document.paginationInfo"/>
      </div>
    </template>
    <NoData v-else/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import router from "@/scripts/router";
import store from "@/scripts/store";
import NoData from "@/components/NoData";
import Pagination from "@/components/Pagination";

function Component(initialize) {
  this.name = "pageManageNews";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Pagination, NoData},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      for (let i = 0; i < 10; i += 1) {
        state.document.list.push({
          title: "Please Wait a moment. Now loading data.",
          createDate: "0000-00-00",
          commentsList: []
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 10,
      },
      document: {
        paginationInfo: {},
        list: [],
      }
    });

    const openModal = (seq) => {
      if (!state.loaded) {
        return;
      }

      store.commit("openModal", {
        name: "News",
        params: {
          newsSeq: seq,
          page: "manage",
          projectSeq: router.app.$route.params.projectSeq,
          projectType: router.app.$route.params.projectType,
        },
        callback: "refresh"
      });
    };

    const load = (pageIndex) => {
      state.args.pageIndex = pageIndex || 1;

      state.loaded = false;
      http.get(`/api/maker/project/${router.app.$route.params.projectType}/${router.app.$route.params.projectSeq}/news`, state.args).then(({data}) => {
        state.loaded = true;
        state.document.paginationInfo = data.body.paginationInfo;
        state.document.list = [];

        if (Array.isArray(data.body.list)) {
          state.document.list = data.body.list;
        }
      });
    };

    return {component, state, load, openModal};
  }
});
</script>

<style lang="scss" scoped>
.manage-news {
  position: relative;

  .act {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: $px-60;
  }

  .page {
    padding-top: $px15;
  }

  &.skeleton {
    .act .btn {
      @include skeleton;
    }

    table {
      th, td {
        > span {
          @include skeleton;
        }
      }
    }
  }
}
</style>